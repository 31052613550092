@import '../node_modules/bootstrap/dist/css/bootstrap.css';

html {
  height: 100%;
}

@font-face {
  font-family: 'bariol_regular';
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/bariol_regular.woff2'), url('./fonts/bariol_regular.woff');
}

@font-face {
  font-family: 'bariol_bold';
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/bariol_bold.woff2'), url('./fonts/bariol_bold.woff');
}

:root {
  --theme-font-family-regular: 'bariol_regular', 'Calibri', 'Helvetica',
    sans-serif;
  --theme-font-family-bold: 'bariol_bold', 'Calibri', 'Helvetica', sans-serif;
}

#root {
  height: 100%;
}

body {
  font-family: var(--theme-font-family-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.25rem;
  height: 100%;
}

.form-control {
  font-size: 16px;
}

.font-weight-bold {
  font-family: var(--theme-font-family-bold);
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--theme-font-family-bold);
}

h1 {
  font-size: 2.3em;
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  background: none;
  border: 0;
  color: inherit;
  font-family: var(--theme-font-family-regular);
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  /* for input */
  user-select: none;
}

@media (min-width: 768px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
}



.ql-editor-dev .ql-size-small {
  font-size: .75em
}

.ql-editor-dev .ql-size-large {
  font-size: 1.5em
}

.ql-editor-dev .ql-size-huge {
  font-size: 2.5em
}

.ql-editor-dev .ql-align-center {
  text-align: center
}

.ql-editor-dev .ql-align-justify {
  text-align: justify
}

.ql-editor-dev .ql-align-right {
  text-align: right
}

.ql-editor-dev .ql-indent-1:not(.ql-direction-rtl) {
  margin-left: 3em
}

.ql-editor-dev li.ql-indent-1:not(.ql-direction-rtl) {
  margin-left: 4.5em
}

.ql-editor-dev .ql-indent-1.ql-direction-rtl.ql-align-right {
  margin-right: 3em
}

.ql-editor-dev li.ql-indent-1.ql-direction-rtl.ql-align-right {
  margin-right: 4.5em
}

.ql-editor-dev .ql-indent-2:not(.ql-direction-rtl) {
  margin-left: 6em
}

.ql-editor-dev li.ql-indent-2:not(.ql-direction-rtl) {
  margin-left: 7.5em
}

.ql-editor-dev .ql-indent-2.ql-direction-rtl.ql-align-right {
  margin-right: 6em
}

.ql-editor-dev li.ql-indent-2.ql-direction-rtl.ql-align-right {
  margin-right: 7.5em
}

.ql-editor-dev .ql-indent-3:not(.ql-direction-rtl) {
  margin-left: 9em
}

.ql-editor-dev li.ql-indent-3:not(.ql-direction-rtl) {
  margin-left: 10.5em
}

.ql-editor-dev .ql-indent-3.ql-direction-rtl.ql-align-right {
  margin-right: 9em
}

.ql-editor-dev li.ql-indent-3.ql-direction-rtl.ql-align-right {
  margin-right: 10.5em
}

.ql-editor-dev li[data-list=bullet] {
  list-style: disc;
}